import React from 'react';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import Banner from '@/components/NotFound/Banner';

export const getStaticProps: GetStaticProps = async ({ locale = '' }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['notFound', 'common', 'path', 'form'])),
    },
  };
};

const NotFoundPage = () => {
  return <Banner />;
};

export default NotFoundPage;
